<template>
  <div
    class="finace-container fami-container"
  >
    <template v-if="!isloading">
      <div class="index-wrap">
        <div class="left">
          <h3>
            <p>提现信息：</p>
            <button
              class="green-border-btn"
              @click="handleWithdraw"
            >
              申请提现
            </button>
          </h3>
          <ul>
            <li>可提现：{{ finaceIndex.commission.active_amount }}元 (可提现 = 已结算 - 已提现 - 处理中 - 保证金  )</li>
            <li>账户已结算：{{ finaceIndex.commission.all_amount }}元 （{{ mouthNum }}月订单未结算，不计入）</li>
            <li>已提现：{{ finaceIndex.commission.has_withdrawal }} 元</li>
            <li>处理中： {{ finaceIndex.commission.frozen_amount }}元 （包含：待审核、待支付订单）</li>
            <li>保证金：{{ deposit }}元 （账户需冻结佣金的5%作为保证金，以防发生用户退款）</li>
          </ul>
          <router-link
            to="/finace/accountinfo"
            class="accountinfo"
          >
            查看对公开票信息
          </router-link>
        </div>

        <!-- <div
          class="right"
          v-if="!isDistributor"
        >
          <h3>
            <p>收款账户：</p>
            <router-link
              to="/finace/accountinfo"
              class="green-border-btn"
            >
              查看对公票信息
            </router-link>
          </h3>
          <ul v-if="isCompany">
            <li>名称：{{ finaceIndex.company.company_name }}</li>
            <li>纳税识别号：{{ finaceIndex.company.taxpayer_code }}</li>
            <li>地址：{{ finaceIndex.company.address }}</li>
            <li>开户银行：{{ finaceIndex.company.bank_deposit }}</li>
            <li>银行账号：{{ finaceIndex.company.bank_account }}</li>
          </ul>
          <ul v-else>
            <li>名称：{{ finaceIndex.company.agent_role }}</li>
            <li>个人账号：{{ finaceIndex.company.personal_account }}</li>
            <li>账号类型：{{ finaceIndex.company.personal_account_type }}</li>
          </ul>
        </div> -->
      </div>

      <el-table
        :data="withdrawalList"
        style="width: 100%"
        border
        fit
        highlight-current-row
      >
        <el-table-column
          v-for="(item) in tableColumn"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="(item.width ? item.width + 'px': 'auto')"
          :min-width="(item.minWidth ? item.minWidth + 'px' : 0)"
          align="center"
        />
        <el-table-column
          label="操作"
          width="100px"
          prop="operation"
          align="center"
        >
          <template #default="scope">
            <el-button
              @click="handleClick(scope.row)"
              v-if="scope.row.status === 2"
              type="text"
              size="small"
            >
              撤销
            </el-button>
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              v-else
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          v-model:page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listTotal"
          :small="mobile"
          ref="paginationRef"
        />
      </div>
    </template>
  </div>
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  Ref,
  computed,
  // watch,
  nextTick
} from 'vue'
import requestMiddle, { RequestInter } from '@/utils/request'
import { requestCapitalIndex, requestCapitalWithdrawal, CapitalWithdrawal, revokeWithdrawal } from '@/apis/order'
import { IndexMessage, AgentType } from '@/model/orderModel'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from '@/store'
import { UserMutationTypes } from '@/store/modules/user/mutation-types'
import { parseTime } from '@/utils'
import { DeviceType } from '@/store/modules/app/state'
import { AGENTSTATUS, AGENTTYPE, Company } from '@/store/modules/user/state'
import storage, { StorageType } from '@/utils/storage'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// import Tips from '@/components/tips/Index.vue'
interface FinaceIndex {
  commission: IndexMessage<string>
  company: IndexMessage<string>
  agent_status: AGENTSTATUS
}
interface DataMapInter {
  withdrawalList: IndexMessage<string>[]
  pageTotal: number
  listTotal: number
  isFrist: boolean
  isloading: boolean
  currentPage: number
  prevPage: number
  page: number
  pageSize: number
  getFinaceData: (option?: CapitalWithdrawal, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    // PlateTitle
    // Tips
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const finaceIndex: Ref<FinaceIndex | {}> = ref({})
    const paginationRef = ref(null)
    const dataMap: DataMapInter = reactive({
      withdrawalList: [],
      pageTotal: 0,
      listTotal: 0,
      isFrist: true,
      isloading: true,
      currentPage: 0,
      prevPage: -1,
      pageSize: 10,
      page: 1,
      tableColumn: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'order_sn',
          label: '订单号',
          minWidth: '200'
        },
        {
          prop: 'withdrawal',
          label: '提现金额',
          // width: '400',
          width: '150'
        },
        {
          prop: 'account_num',
          label: '收款账号',
          width: '150'
        },
        {
          prop: 'create_time',
          label: '申请时间',
          width: '200'
        },
        {
          prop: 'update_time',
          label: '处理时间',
          width: '200'
        },
        {
          prop: 'status_text',
          label: '状态',
          width: '200'
        }
      ],
      getFinaceData: async(option: CapitalWithdrawal = { page: 1, size: 10 }, successCb?, failCb?) => {
        const newOption = {}
        Object.assign(newOption, {
          size: dataMap.pageSize,
          page: dataMap.currentPage
        }, option)
        if (dataMap.isFrist) {
          const indexData = await requestMiddle({
            requestMethodName: requestCapitalIndex
          } as RequestInter)
          try {
            finaceIndex.value = indexData
            store.commit(UserMutationTypes.SET_COMPANY, indexData.company)
            const { bank_account, personal_account, is_company, bank_deposit, personal_account_type } = indexData.company
            store.commit(UserMutationTypes.SET_ACCOUNT, indexData.commission.active_amount)
            store.commit(UserMutationTypes.SET_BANKACCOUNT, is_company === AGENTTYPE.COMPANY ? (`${bank_deposit}  ${bank_account}`) : (`${personal_account_type}  ${personal_account}`))
            dataMap.isFrist = false
          } catch (error) {
            console.log(error)
          }
        }
        const withdrawalList = await requestMiddle({
          requestMethodName: requestCapitalWithdrawal,
          option: newOption
        } as RequestInter)
        dataMap.isloading = false
        try {
          const { data, last_page, total } = withdrawalList
          let i = 1
          dataMap.withdrawalList = data.map((item: IndexMessage<string | number | null>) => {
            item.update_time = parseTime(item.update_time)
            item.create_time = parseTime(item.create_time)
            item.serial = i++
            return item
          })
          dataMap.pageTotal = last_page
          dataMap.listTotal = total
          successCb && successCb()
        } catch (error) {
          console.log(error)
          failCb && failCb()
        }
      }
    })
    const deposit = computed(() => {
      return (Number((finaceIndex.value as FinaceIndex).commission.all_amount) * 100 * 5 / 10000).toFixed(2)
    })

    const methods = reactive({
      handleSizeChange(val: number) {
        dataMap.getFinaceData({ size: val })
      },
      handleCurrentChange: (val: number) => {
        const page = dataMap.currentPage
        // if (val === page) return
        dataMap.getFinaceData({ page: val }, () => {
          dataMap.currentPage = val
        }, () => {
          dataMap.currentPage = page
          nextTick(() => {
            (paginationRef.value as any).internalCurrentPage = page
          })
        })
      },
      handleWithdraw: () => {
        if (Number((finaceIndex.value as FinaceIndex).commission.frozen_amount) > 0) {
          ElMessage({
            type: 'warning',
            message: '您有提现订单还未完结，完结后，在下一个提现周期可以进行提现。',
            offset: 300
          })
        } else if ((finaceIndex.value as FinaceIndex).agent_status === AGENTSTATUS.FREEZE) {
          ElMessage({
            type: 'warning',
            message: '您的账号已经被冻结',
            offset: 300
          })
        } else if (Number((finaceIndex.value as FinaceIndex).commission.now_day) <= 5) {
          ElMessage({
            type: 'warning',
            message: '订单在结算中，请在5号后申请提现。',
            offset: 300
          })
        } else {
          router.push('/finace/withdraw')
        }
      },
      handleClick: (row: IndexMessage<string | number>) => {
        console.log(row)
        let path = ''
        switch (row.status) {
          case 1:
            path = 'complete'
            break
          case 2:
            ElMessageBox.confirm('是否撤销提现申请?', '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async() => {
              await revokeWithdrawal({ id: row.id }).then((res) => {
                if (res?.code === 200) {
                  ElMessage({
                    type: 'success',
                    message: '撤销提现申请成功'
                  })
                  dataMap.isFrist = true
                  dataMap.getFinaceData()
                }
              })
            }).catch(() => {
              console.log('已取消')
            })
            break
          case 3:
            path = 'unpaid'
            break
          case 4:
            path = 'rejected'
            break
          case 5:
            path = 'undone'
            break
        }
        path && router.push({
          path: `/finace/${path}`,
          query: { id: row.id }
        }).catch(err => {
          console.warn(err)
        })
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })
    const isCompany = computed(() => {
      return (store.state.user.company as Company).is_company === AGENTTYPE.COMPANY
    })

    const isDistributor = computed(() => {
      return store.state.user.userinfo.agent_type === AgentType.DISTRIBUTOR
    })

    const mouthNum = computed(() => {
      return new Date().getMonth() + 1
    })
    onBeforeRouteLeave(() => {
      storage.rcSetItem(StorageType.session, 'finacePage', dataMap.currentPage)
    })

    onBeforeMount(() => {
      let page = storage.rcGetItem(StorageType.session, 'finacePage')
      page = page ? Number(page) : 1
      nextTick(() => {
        methods.handleCurrentChange(page)
      })
    })

    return {
      ...toRefs(dataMap),
      ...toRefs(methods),
      finaceIndex,
      mobile,
      isCompany,
      paginationRef,
      mouthNum,
      deposit,
      isDistributor
    }
  }
})
</script>

<style lang="scss" scoped>
  .finace-container{
    .pagination{
      display: flex;
      justify-content: flex-end;
      margin-top:30px;
    }
    .active-amount{
      display: flex;
    }
    .accountinfo{
      font-size: 14px;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      margin-top:10px;
      color:#51AA8D ;
      text-decoration: underline;
    }
  }
  .index-wrap{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    h3{
      color: #333;
      font-size: 18px;
      margin-bottom: 24px;
      display: flex;
      align-items:center;
      button{
        font-weight: normal;
        margin-left: 40px;
      }
    }
    ul{
      list-style: none;
      color: #666;
      padding:0;
      line-height: 32px;
    }
    .left,.right{
      flex:1;
    }
  }
  @media (max-width: 767px){
    .index-wrap{
      flex-direction: column;
      .left,.right{
        width: 100%;
      }
      .right{
        margin-top: 30px;
      }
      .active-amount{
        ::v-deep(.tooltip .text){
          left: 50%;
          top:20px;
          transform: translate(-50%, 15px);
          &::before, &::after{
            top:0;
            left:50%;
          }
          &::after{
            top:5px
          }
        }
      }
    }
    ::v-deep(.el-pagination){
      .el-pagination__total,.el-pagination__sizes,.el-select,.el-pagination__jump{
        display: none;
      }
    }
  }
  @media (max-width: 380px){
    .index-wrap h3{
      align-items:center;
      button{
        margin-left: 10px;
      }
    }
  }
   @media (max-width: 374px){
    .index-wrap h3{
      flex-direction: column;
      align-items:flex-start;
      button, a{
        margin:10px 0 0 0;
      }
    }
  }
</style>
