
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  Ref,
  computed,
  // watch,
  nextTick
} from 'vue'
import requestMiddle, { RequestInter } from '@/utils/request'
import { requestCapitalIndex, requestCapitalWithdrawal, CapitalWithdrawal, revokeWithdrawal } from '@/apis/order'
import { IndexMessage, AgentType } from '@/model/orderModel'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from '@/store'
import { UserMutationTypes } from '@/store/modules/user/mutation-types'
import { parseTime } from '@/utils'
import { DeviceType } from '@/store/modules/app/state'
import { AGENTSTATUS, AGENTTYPE, Company } from '@/store/modules/user/state'
import storage, { StorageType } from '@/utils/storage'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
// import Tips from '@/components/tips/Index.vue'
interface FinaceIndex {
  commission: IndexMessage<string>
  company: IndexMessage<string>
  agent_status: AGENTSTATUS
}
interface DataMapInter {
  withdrawalList: IndexMessage<string>[]
  pageTotal: number
  listTotal: number
  isFrist: boolean
  isloading: boolean
  currentPage: number
  prevPage: number
  page: number
  pageSize: number
  getFinaceData: (option?: CapitalWithdrawal, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    // PlateTitle
    // Tips
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const finaceIndex: Ref<FinaceIndex | {}> = ref({})
    const paginationRef = ref(null)
    const dataMap: DataMapInter = reactive({
      withdrawalList: [],
      pageTotal: 0,
      listTotal: 0,
      isFrist: true,
      isloading: true,
      currentPage: 0,
      prevPage: -1,
      pageSize: 10,
      page: 1,
      tableColumn: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'order_sn',
          label: '订单号',
          minWidth: '200'
        },
        {
          prop: 'withdrawal',
          label: '提现金额',
          // width: '400',
          width: '150'
        },
        {
          prop: 'account_num',
          label: '收款账号',
          width: '150'
        },
        {
          prop: 'create_time',
          label: '申请时间',
          width: '200'
        },
        {
          prop: 'update_time',
          label: '处理时间',
          width: '200'
        },
        {
          prop: 'status_text',
          label: '状态',
          width: '200'
        }
      ],
      getFinaceData: async(option: CapitalWithdrawal = { page: 1, size: 10 }, successCb?, failCb?) => {
        const newOption = {}
        Object.assign(newOption, {
          size: dataMap.pageSize,
          page: dataMap.currentPage
        }, option)
        if (dataMap.isFrist) {
          const indexData = await requestMiddle({
            requestMethodName: requestCapitalIndex
          } as RequestInter)
          try {
            finaceIndex.value = indexData
            store.commit(UserMutationTypes.SET_COMPANY, indexData.company)
            const { bank_account, personal_account, is_company, bank_deposit, personal_account_type } = indexData.company
            store.commit(UserMutationTypes.SET_ACCOUNT, indexData.commission.active_amount)
            store.commit(UserMutationTypes.SET_BANKACCOUNT, is_company === AGENTTYPE.COMPANY ? (`${bank_deposit}  ${bank_account}`) : (`${personal_account_type}  ${personal_account}`))
            dataMap.isFrist = false
          } catch (error) {
            console.log(error)
          }
        }
        const withdrawalList = await requestMiddle({
          requestMethodName: requestCapitalWithdrawal,
          option: newOption
        } as RequestInter)
        dataMap.isloading = false
        try {
          const { data, last_page, total } = withdrawalList
          let i = 1
          dataMap.withdrawalList = data.map((item: IndexMessage<string | number | null>) => {
            item.update_time = parseTime(item.update_time)
            item.create_time = parseTime(item.create_time)
            item.serial = i++
            return item
          })
          dataMap.pageTotal = last_page
          dataMap.listTotal = total
          successCb && successCb()
        } catch (error) {
          console.log(error)
          failCb && failCb()
        }
      }
    })
    const deposit = computed(() => {
      return (Number((finaceIndex.value as FinaceIndex).commission.all_amount) * 100 * 5 / 10000).toFixed(2)
    })

    const methods = reactive({
      handleSizeChange(val: number) {
        dataMap.getFinaceData({ size: val })
      },
      handleCurrentChange: (val: number) => {
        const page = dataMap.currentPage
        // if (val === page) return
        dataMap.getFinaceData({ page: val }, () => {
          dataMap.currentPage = val
        }, () => {
          dataMap.currentPage = page
          nextTick(() => {
            (paginationRef.value as any).internalCurrentPage = page
          })
        })
      },
      handleWithdraw: () => {
        if (Number((finaceIndex.value as FinaceIndex).commission.frozen_amount) > 0) {
          ElMessage({
            type: 'warning',
            message: '您有提现订单还未完结，完结后，在下一个提现周期可以进行提现。',
            offset: 300
          })
        } else if ((finaceIndex.value as FinaceIndex).agent_status === AGENTSTATUS.FREEZE) {
          ElMessage({
            type: 'warning',
            message: '您的账号已经被冻结',
            offset: 300
          })
        } else if (Number((finaceIndex.value as FinaceIndex).commission.now_day) <= 5) {
          ElMessage({
            type: 'warning',
            message: '订单在结算中，请在5号后申请提现。',
            offset: 300
          })
        } else {
          router.push('/finace/withdraw')
        }
      },
      handleClick: (row: IndexMessage<string | number>) => {
        console.log(row)
        let path = ''
        switch (row.status) {
          case 1:
            path = 'complete'
            break
          case 2:
            ElMessageBox.confirm('是否撤销提现申请?', '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async() => {
              await revokeWithdrawal({ id: row.id }).then((res) => {
                if (res?.code === 200) {
                  ElMessage({
                    type: 'success',
                    message: '撤销提现申请成功'
                  })
                  dataMap.isFrist = true
                  dataMap.getFinaceData()
                }
              })
            }).catch(() => {
              console.log('已取消')
            })
            break
          case 3:
            path = 'unpaid'
            break
          case 4:
            path = 'rejected'
            break
          case 5:
            path = 'undone'
            break
        }
        path && router.push({
          path: `/finace/${path}`,
          query: { id: row.id }
        }).catch(err => {
          console.warn(err)
        })
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })
    const isCompany = computed(() => {
      return (store.state.user.company as Company).is_company === AGENTTYPE.COMPANY
    })

    const isDistributor = computed(() => {
      return store.state.user.userinfo.agent_type === AgentType.DISTRIBUTOR
    })

    const mouthNum = computed(() => {
      return new Date().getMonth() + 1
    })
    onBeforeRouteLeave(() => {
      storage.rcSetItem(StorageType.session, 'finacePage', dataMap.currentPage)
    })

    onBeforeMount(() => {
      let page = storage.rcGetItem(StorageType.session, 'finacePage')
      page = page ? Number(page) : 1
      nextTick(() => {
        methods.handleCurrentChange(page)
      })
    })

    return {
      ...toRefs(dataMap),
      ...toRefs(methods),
      finaceIndex,
      mobile,
      isCompany,
      paginationRef,
      mouthNum,
      deposit,
      isDistributor
    }
  }
})
